<!-- 技术成果 二级-->

<template>
  <div class="box">
    <div class="nav_top">
      <div class="top">
        <top-nav></top-nav>
      </div>
      <div class="text">
        <div class="first">绿水青山就是金山银山</div>
      </div>
    </div>
    <div style="background-color: #f5f5f5;">
      <div class="content_c">
        <el-tabs :tab-position="tabPosition" v-model="defaultName" @tab-click="handleClick">
          <el-tab-pane :disabled="true">
            <span slot="label"><img style="
    width: 30px;
    height: 6px;" src="../../assets/center/left.png" alt=""> 技术成果 <img style="
    width: 30px;
    height: 6px;" src="../../assets/center/right.png" alt=""></span>
          </el-tab-pane>
          <el-tab-pane label="工程项目">


            <div class="crumbs">
              <div class="crumbs_img"><img src="../../assets/home.png" alt=""></div>
              <div class="crumbs_text">
                您当前得位置:</div>
              <div class="crumbs_content">
                <span>首页 ></span>
                <span>技术成果 ></span>
                <span>工程项目</span>
              </div>
            </div>

            <div class="centent_center">
              <div class="list_child">
                <div :class="[childActive==index?'child_active':'','list_child_item']"
                  v-for="(item,index) in engineeringList" :key="index" @click="engineeringTable(index,item.pid)">
                  {{item.typename}}
                </div>
              </div>
              <div class="list_child_content grid" v-if="childProject&&childProject.length>0">
                <div class="content_item g-col-6 g-col-md-4" v-for="(item,index) in childProject" :key="index"  @click="goDetails(item.pid)">
                  <div class="part content_item_part_img">
                    <img :src="item.pathUpload" alt="">
                  </div>
                  <div class="part content_item_part_text">
                    <div class="title">{{item.title||''}}</div>
                    <div class="title">{{item.other||''}}</div>
                  </div>
                </div>
              </div>
              <el-empty description="暂无数据" v-else></el-empty>
              <!-- <div class="content_list"  v-for="(item, index) in newsList" :key="index"
              @click="goDetails(item.pid)">
              <div class="img" v-if="item.pathUpload">
                <img :src="item.pathUpload" alt="" />
              </div>
              <div class="list_content">
                <div class="title">{{ item.title }}</div>
                <div class="text">
                  <div class="content">{{item.other}}</div>
                </div>
                <div class="time">时间{{ item.submitTime }}</div>
              </div>
            </div> -->
              <div class="pagination">
                <el-button size="small" @click="pervious">上一页</el-button>
                <el-pagination @current-change="handleCurrentChange" :current-page="currentPage1" background
                  :page-size="pageSize" layout="pager" :total="total" ref="pageGroup">
                </el-pagination>
                <el-button size="small" @click="next">下一页</el-button>
              </div>
            </div>

            <!-- <div class="centent_center">
              <div class="part">
                <p class="part" v-html="profileListtwo[0].contentEdit"></p>
              </div>

              <div class="part" style="padding-top: 1.25rem;">
                <div>
                  <p class="part" v-html="profileListtwo[1].contentEdit"></p>
                </div>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane :label="item.typename" v-for="(item, index) in leftList" :key="index">
            <div class="crumbs">
              <div class="crumbs_img"><img src="../../assets/home.png" alt=""></div>
              <div class="crumbs_text">
                您当前得位置:</div>
              <div class="crumbs_content">
                <span>首页 ></span>
                <span>技术成果 ></span>
                <span>{{ item.typename }}</span>
              </div>
            </div>
            <div class="blog-detail">
              <div class="text mb-30">
                <p class="rich" v-html="detailsItem.contentEdit"></p>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../../components/topNav";
import bottom from "../../components/bottom";
import { province, menuList, getInfo, listPain } from "../../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      tabPosition: "left",
      newsList: [],
      leftList: [],
      detailsItem: {},
      defaultName: '1',
      childProject: [],//工程项目子类文章
      childActive: 0,//默认选中第一个
      currentPage1: 1,
      total: 0,
      pageSize: 5,
      engineeringList: [],//工程项目的子菜单
      // engineeringList: [
      //   {
      //     name: '净水药剂'
      //   },
      //   {
      //     name: '检测项目'
      //   },
      //   {
      //     name: '水生态治理'
      //   },
      //   {
      //     name: '管网工程'
      //   },
      // ],
    };
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async handleCurrentChange(val) {
      this.currentPage1 = val
      // this._intoNews()
    },

    pervious() {
      this.$refs.pageGroup.prev();
    },
    next() {
      this.$refs.pageGroup.next();
    },
    //html
    clearRichText(texts) {
      // console.log(texts,'100');
      let text1 = texts.replace(/<\/?()[^>]*>/g, ""); //去除图片、表格
      let text2 = text1.replace(/<\/?.+?>/g, ""); //去除标签包裹
      let text3 = text2.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
      let text4 = text3.replace(/ /g, ""); //去除空格
      // let text4 = texts.replace(/ /g,"");
      // console.log(text4,'4000');
      return text4;
    },

    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    async init() {
      let res = await menuList();
      if (res.ResultCode == 0) {
        console.log(res, '获取菜单');
        this.leftList = res.Data[3].childs.splice(1);
        this.engineeringList=res.Data[3].childs[0].childs
        this.getDateThree(this.engineeringList[0].pid)
        this.getDate(this.leftList[0].pid);
        this.getDateProject(res.Data[3].childs[0].pid);
      }
    },
    async details(e) {
      let res = await getInfo({
        pid: e,
      });
      if (res.ResultCode == 0) {
        res.Data.contentEdit = res.Data.contentEdit.replace(/pt/g, 'px')
        this.detailsItem = res.Data;
      }
    },
    async handleClick(tab) {
      console.log(tab.index, this.leftList[tab.index]);
      if(tab.index==1) return
      this.getDate(this.leftList[tab.index - 2].pid);
    },
    async getDate(e) {
      let pids = "";
      let res = await listPain({
        typeidSelsource: e,
        page: 1,
        limit: 10,
      });
      if (res.ResultCode == 0) {
        // console.log(res.Data, "res列表");
        pids = res.Data.Items[0].pid;
      }
      this.details(pids);
    },
    // 获取类型三级的列表
    
    async getDateThree(e) {
      let pids = "";
      let res = await listPain({
        typeidSelsource: e,
        page: 1,
        limit: 6,
      });
      if (res.ResultCode == 0) {
        this.childProject=res.Data.Items
        console.log(res.Data, "res列表");
        if(res.Data.Items&&res.Data.Items.length>0){
        pids = res.Data.Items[0].pid;

        }
      }
      this.details(pids);
    },
    async getDateProject(e) {
      let res = await listPain({
        typeidSelsource: e,
        page: 1,
        limit: 10,
      });
      if (res.ResultCode == 0) {
        // this.engineeringList = res.Data.Item
        this.childProject=res.Data.Item;
        if(res.Data.Items&&res.Data.Items.length>0){
        this.engineeringDetails(res.Data.Items[0].pid)

        }
      }
    },
    goDetails(e) {

      this.$router.push({
        path: '/listDetails',
        query: {
          pid: e,
          type: 1,
          isTitle: 1
        },
      });
    },
    engineeringTable(index, e) {
      this.childActive = index
      let pids = "";
      pids = e;
      console.log(e, 'pid');
      this.getDateThree(pids);
    },

    async engineeringDetails(e) {
      this.childProject = []

      let res = await getInfo({
        pid: e,
      });
      if (res.ResultCode == 0) {
        console.log(res, '0909');
        this.childProject.push(res.Data)
        // res.Data.contentEdit = res.Data.contentEdit.replace(/pt/g, 'px')
        // this.detailsItem = res.Data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 工程项目开始
.centent_center {
  // height: 700px;
  min-height: 400px;
  background-color: #fff;
  padding: 5px 20px;
  margin-bottom: 50px;
  min-width: 400px;
}

.list_child {
  min-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .list_child_item {
    width: 150px;
    height: 40px;
    line-height: 40px;
    background-color: #eff3f8;
    text-align: center;
    color: #333;
    margin: 10px 5px;
    cursor: pointer;
  }

  .list_child_item:hover {
    background-color: #1157d0;
    color: #fff;
  }

  .child_active {
    background-color: #1157d0;
    color: #fff;
  }
}

.list_child_content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  align-items: center;

  .content_item {
    width: 332px;
    height: 255px;
    margin: 10px;
    cursor:pointer;

    .part {
      width: 332px;
      height: 255px;
    }
  }

  .content_item_part_img {
    position: absolute;
    background-color: #eff3f8;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content_item_part_text {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #fff;
    font-size: 26px;
    display: none;
  }

  .content_item:hover .content_item_part_text {

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// 工程项目结束


 // 面包屑
 .crumbs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #666666;
    font-size: 14px;
    font-weight: 500;
  
    height: 50px;
    &_img {
      width: 18px;
      height: 18px;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    &_text {
      padding-left: 10px;
    }
  
    &_content {
      color: #666666;
      font-size: 14px;
    }
  }


// 分页器
.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  // height: 1.875rem;
  // line-height: 1.875rem;
  margin: 0 auto;
}

.rich {

  // width: 100px;
  ::v-deep img {
    // width: 800px !important;
    width: 100%;
    margin: auto;
  }

  ::v-deep p {
    line-height: 3;
    text-indent: 0;
  }

  ::v-deep h3 p.MsoNormal {
    margin: 20px 0;

  }

  ::v-deep table.MsoTableGrid.layui-table {
    margin: auto;
  }

  ::v-deep table tr td {
    border: 1px solid #333 !important;
  }

  ::v-deep table tr th {

    font-size: 14px !important;
  }

  ::v-deep table tr td {
    font-size: 12px !important;
    text-align: center !important;
  }
}

.box {
  width: 100%;
  box-sizing: border-box;

  .nav_top {
    width: 100%;
    height: 50vh;
    background-image: url("../../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .text {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      font-family: 'YanShiYouRanXiaoKai';

      transition: bounce-in 3s;

      .first {
        font-size: 6.875rem;
        // font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }

    .top {
      width: 100%;
      height: 100px;
      background-size: 100% 100px;
      background-repeat: no-repeat;
    }

    h1 {
      font-size: 66px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 140px;
    }

    h2 {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 51px;
    }
  }

  .content_c {
    width: 87.5rem;
    // height: 1000px;
    margin: 0 auto;
    padding-top: 4.5rem;
    padding-bottom: 3.125rem;
    // 新闻列表

    .blog-detail {
      width: 100%;
      height: 100%;
      padding: 0 9.375rem;
      // margin: 1.25rem auto;
      overflow: hidden;
      text-align: left;
      background-color: #fff;
    }

    .blog-detail .detail-title {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      margin: 1.25rem auto;
    }

    .blog-detail .text {
      width: 100%;
    }

    .blog-detail .text .time {
      text-align: center;
      margin: 1.25rem auto;
    }

    p.rich {
      margin: auto;
    }

    .p {
      text-indent: 1.875rem;
    }
  }

  .content5 {
    width: 100%;
    height: 19.375rem;
    background: #f5f5f5;
  }
}

::v-deep {
  .rich>>>img {
    width: 100% !important;
  }

  .rich>>>table tr td {
    border: none !important;

  }

 
  .el-tabs__content {
    // background-color: #fff;
  }

  .el-tabs__nav.is-left {
    width: 200px;
    background-color: #fff;
  }

  .el-tabs__item {
    height: 60px;
    line-height: 60px;
  }

  .el-tabs--left .el-tabs__item.is-left {
    text-align: center;
    font-size: 18px;
    // font-family: AlibabaPuHuiTi-2-55-Regular;

    span {
      font-size: 20px;
    }


  }
  .el-tabs__item:hover {
    background-color: rgba(36, 119, 228, .1);
  }

  .el-tabs__item.is-active {
    background-color: rgba(36, 119, 228, .1);

  }

  #tab-0 {
    background-color: #356CEB;
    color: #fff;

  }

  .el-tabs__active-bar.is-right {}

  .el-tab-pane {
    margin: 0 100px;
    margin-right: 0;
  }
}
</style>
